import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import IsLogin from "../../Common/IsLogin";
import { LoaderComponent } from "../../Common/LoaderComponent";
import Http from "../../Common/Http";

const StaffDashboardContent = (attendanceGiven) => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const [error, setError] = useState({});
  const [receiveData, setReceiveData] = useState({});
  const [id, setId] = useState({});
  const [distance, setDistance] = useState('');
  const [loading, setLoading] = useState(false);
  // const [attendanceGiven, setAttendanceGiven] = useState(false);
  const [showMovementSlip, setShowMovementSlip] = useState(false);
  const [movementType, setMovementType] = useState(''); // official or personal
  const [remarks, setRemarks] = useState('');
  const [time, setTime] = useState('');
  const [endtime, setEndtime] = useState('');
  let modal;
  const signOut = () => {
    localStorage.clear();
    navigate("/login");
  };

  useEffect(() => {
    if (showMovementSlip) {
      modal = new window.bootstrap.Modal(document.getElementById('movementSlipModal'));
      modal.show();
    }
  }, [showMovementSlip]);

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Earth's radius in kilometers
    const dLat = (lat2 - lat1) * Math.PI / 180;
    const dLon = (lon2 - lon1) * Math.PI / 180;
    const a = Math.sin(dLat / 2) ** 2 + 
            Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
            Math.sin(dLon / 2) ** 2;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in kilometers
  };

  // Function to obtain the current geolocation
  const getGeoLocation = () => {
      return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
          position => resolve({ lat: position.coords.latitude, lng: position.coords.longitude }),
          error => reject(error)
          );
      } else {
          reject(new Error("Geolocation is not supported by this browser."));
      }
      });
  };
  const formSubmit = async () => {
    try {
      const location = await getGeoLocation();
      const fixedLat = 23.7644357;
      const fixedLng = 90.358958;
      const distance = calculateDistance(location.lat, location.lng, fixedLat, fixedLng);

      console.log(`Distance to fixed point is ${distance.toFixed(2)} kilometers.`);

      if (distance > 0.01) {
        setShowMovementSlip(true);
      } else {
        const is_movement_slip = false;
        const dataToSend = { ...inputs, ...location, distance, is_movement_slip};
        const response = await Http.post("/attendance-app-save", dataToSend);
        navigate("/attendance", {
          state: { message: response.data.message, type: "success" }
        });
      }
    } catch (error) {
      console.error("Error obtaining location or sending data:", error);
      setError(error.response ? error.response.data.errors : error);
    }
  };
  const handleMovementSlipSubmit = async () => {
    try {
      const location = await getGeoLocation();
      const is_movement_slip = true;
      const type = movementType;
      const now = new Date();
      const attendance_time = now.toTimeString().slice(0, 5);
      const entry_time = time;
      const end_time = endtime;
      const dataToSend = { type, remarks, attendance_time,entry_time,end_time, ...location, ...inputs, distance, is_movement_slip };
      
      const response = await Http.post("/attendance-app-save", dataToSend);
      
      // Assuming the response contains a `status` field to check for success

      // Hide the modal after form submission
      const modalElement = document.getElementById('movementSlipModal');
      const modalInstance = window.bootstrap.Modal.getInstance(modalElement); // Get existing modal instance

      if (modalInstance) {
        modalInstance.hide(); // Hide the modal
      }
      if (response.data.status === true) {
        navigate("/attendance", {
          state: { message: response.data.message, type: "success" }
        });
      } else {
        console.error("Submission failed:", response.data.message);
        setError(response.data.message);
      }
    } catch (error) {
      console.error("Error submitting movement slip:", error);
      setError(error.response ? error.response.data.errors : error);
    }
  };
  return (
    <div>
      <IsLogin></IsLogin>
      <LoaderComponent />
      <div className="row">
        <div className="col-md-6">
          {(attendanceGiven.attendanceGiven == null?<>
            <span style={{ cursor:"pointer" }} onClick={formSubmit} className="nav-link staff-dashboard-link staff-dashboard-link-box-shadow1">
              <i className="fa fa-clone" aria-hidden="true"></i>
              &nbsp;&nbsp; Give Attendance
              {/* <hr style={{ backgroundColor:"green" }} /> */}
          </span>
          </>:<>
          <Link to="/attendance" className="nav-link staff-dashboard-link staff-dashboard-link-box-shadow1">
              <i className="fa fa-clone" aria-hidden="true"></i>
              &nbsp;&nbsp; Checkout
              {/* <hr style={{ backgroundColor:"green" }} /> */}
          </Link>
          </>)}
        </div>
        <div className="col-md-6">
          <Link to="/leave-application" className="nav-link staff-dashboard-link staff-dashboard-link-box-shadow1">
              <i className="fa fa-clone" aria-hidden="true"></i>
              &nbsp;&nbsp; Leave Applicationn
              {/* <hr style={{ backgroundColor:"green" }} /> */}
          </Link>
        </div>
        <div className="col-md-6">
          <Link to="/movement-slip" className="nav-link staff-dashboard-link staff-dashboard-link-box-shadow1">
              <i className="fa fa-clone" aria-hidden="true"></i>
              &nbsp;&nbsp; Movement Slip
              {/* <hr style={{ backgroundColor:"green" }} /> */}
          </Link>
        </div>
        <div className="col-md-6">
          <Link to="/billsubmission" className="nav-link staff-dashboard-link staff-dashboard-link-box-shadow1">
              <i className="fa fa-clone" aria-hidden="true"></i>
              &nbsp;&nbsp; Bill Submission
              {/* <hr style={{ backgroundColor:"green" }} /> */}
          </Link>
        </div>
        <div className="col-md-6">
          <Link to="/reports" className="nav-link staff-dashboard-link staff-dashboard-link-box-shadow1">
              <i className="fa fa-clone" aria-hidden="true"></i>
              &nbsp;&nbsp; Reports
              {/* <hr style={{ backgroundColor:"green" }} /> */}
          </Link>
        </div>
        <div className="col-md-6">
          <label style={{ cursor: "pointer" }} onClick={signOut} className="nav-link staff-dashboard-link staff-dashboard-link-box-shadow1">
            <i className="icon ion-android-exit exitIcon tx-22" aria-hidden="true"></i>
              &nbsp;&nbsp; Logout
              {/* <hr style={{ backgroundColor:"green" }} /> */}
          </label>
        </div>
      </div>

        {showMovementSlip && (
          <div className="movement-slip p-5">
              {/* <h3 className="text-center">Movement Slip</h3><hr />
              <div className="form-control">
              <label>Type of Movement : </label>
              <select className="form-control" value={movementType} onChange={e => setMovementType(e.target.value)}>
                  <option value="">Select</option>
                  <option value="official">Official</option>
                  <option value="personal">Personal</option>
              </select>
              </div>
              <div className="form-control mt-4">
              <label>Expected Time to reach Office : </label>
              <input type="time"
                  className="form-control"
                  value={time}
                  onChange={e => setTime(e.target.value)}
              />
              </div>
              <div className="form-control mt-4">
              <label>Remarks</label>
              <textarea
                  className="form-control"
                  value={remarks}
                  onChange={e => setRemarks(e.target.value)}
              />
              </div>
              <button className="btn btn-success mt-4" onClick={handleMovementSlipSubmit}>Submit Movement Slip</button> */}












              <div className="modal fade" id="movementSlipModal" tabIndex="-1" aria-labelledby="movementSlipModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="movementSlipModalLabel">Movement Slip</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                      <div className="form-group">
                        <label>Type of Movement:</label>
                        <select className="form-control" value={movementType} onChange={e => setMovementType(e.target.value)}>
                          <option value="">Select</option>
                          <option value="official">Official</option>
                          <option value="personal">Personal</option>
                        </select>
                      </div>
                      <div className="form-group mt-4">
                        <label>Start Time:</label>
                        <input type="time" className="form-control" value={time} onChange={e => setTime(e.target.value)} />
                      </div>
                      <div className="form-group mt-4">
                        <label>Expected Time to Reach Office:</label>
                        <input type="time" className="form-control" value={endtime} onChange={e => setEndtime(e.target.value)} />
                      </div>
                      <div className="form-group mt-4">
                        <label>Remarks:</label>
                        <textarea className="form-control" value={remarks} onChange={e => setRemarks(e.target.value)} />
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                      <button type="button" className="btn btn-success" onClick={handleMovementSlipSubmit}>Submit Movement Slip</button>
                    </div>
                  </div>
                </div>
              </div>



          </div>
        )}
    </div>
  );
};

export default StaffDashboardContent;
